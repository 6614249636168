var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                [
                  _c(
                    "div",
                    { attrs: { slot: "header" }, slot: "header" },
                    [
                      _vm._v(" Exports "),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-3",
                          attrs: { size: "sm", variant: "primary" },
                          on: { click: _vm.loadJobs }
                        },
                        [_vm._v("Refresh")]
                      )
                    ],
                    1
                  ),
                  _c("b-spinner", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    attrs: { small: "", label: "Spinning" }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.loading && _vm.exportJobs.length == 0,
                          expression: "!loading && (exportJobs.length == 0)"
                        }
                      ]
                    },
                    [_vm._v(" Geen exports gevonden. ")]
                  ),
                  _c("b-pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.exportJobs.length > _vm.perPage,
                        expression: "(exportJobs.length > perPage)"
                      }
                    ],
                    attrs: {
                      "total-rows": _vm.exportJobs.length,
                      "per-page": _vm.perPage,
                      align: "fill",
                      size: "sm"
                    },
                    model: {
                      value: _vm.page,
                      callback: function($$v) {
                        _vm.page = $$v
                      },
                      expression: "page"
                    }
                  }),
                  _c("b-table", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loading && _vm.exportJobs.length > 0,
                        expression: "!loading && (exportJobs.length > 0)"
                      }
                    ],
                    attrs: {
                      small: "",
                      "current-page": _vm.page,
                      fields: _vm.fields,
                      "sort-by": "started",
                      "sort-desc": true,
                      "per-page": _vm.perPage,
                      items: _vm.exportJobs
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(started)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.moment(data.item.started).calendar())
                            ),
                            _c("br")
                          ]
                        }
                      },
                      {
                        key: "cell(status)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.statusTexts[data.item.status])
                            ),
                            _c("br")
                          ]
                        }
                      },
                      {
                        key: "cell(filesize)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .numeral(data.item.filesize)
                                    .format("0.0 b")
                                )
                            ),
                            _c("br")
                          ]
                        }
                      },
                      {
                        key: "cell(actions)",
                        fn: function(data) {
                          return [
                            data.item.status == "done"
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "primary",
                                      size: "sm",
                                      href:
                                        "/api/users/me/export-files/" +
                                        data.item.id
                                    }
                                  },
                                  [_vm._v("Download")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }